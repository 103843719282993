import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Redux
import { useDispatch } from 'react-redux';
import { ALERT_FAIL } from 'redux/actions/types.js';

// Form validation libraries
import { useFormik } from 'formik';
import * as yup from 'yup';

import Customer from 'api/CustomerService';

// MUI Components
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Typography,
  Hidden,
  Button,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// components
import PortalTextField from 'components/TextField/PortalTextField';

// styles
const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
  step: {
    padding: '8px 25px',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(21),
  },
  button: {
    marginRight: theme.spacing(2),
    '&:disabled': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.primary.contrastText,
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: '10px',
  },
  showPassword: {
    marginTop: '5px',
    display: 'inline-flex',
    border: 'none',
    padding: '5px 5px',
    borderRadius: '4px',
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  adornment: {
    paddingRight: '5px',
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="+1 (###) ###-####"
      allowEmptyFormatting
      mask="_"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function AccountForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { password, confirmPassword, business_name } = props.validation;
  const { length, uppercase, lowercase, number, specialCharacter, match } =
    props;
  const validationSchema = yup.object({
    password,
    confirmPassword,
    business_name,
  });
  const [show, setShow] = useState(false);
  const [submittedPhonenumberErr, setSubmittedPhonenumberErr] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  // temporary feature flag, disabled for Calrecycle Demo
  const enableBusinessService = false;

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShow((prev) => !prev);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirm((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: {
      email: props.userInfo.email,
      phone_number: props.userInfo.phone_number,
      password: '',
      confirmPassword: '',
      type: props.userInfo.type,
      business_name: props.userInfo.business_name,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (
        submittedPhonenumberErr === values.phone_number &&
        submittedPhonenumberErr !== ''
      ) {
        // do not submit the same number a second time if it errors out the first time
        dispatch({
          type: ALERT_FAIL,
          payload: t('signup.form.phoneRetryError'),
        });
        setSubmitting(false);
      } else if (!values.email && !values.phone_number) {
        dispatch({
          type: ALERT_FAIL,
          payload: t('signup.form.pleaseEnterEmailOrPhone'),
        });
        setSubmitting(false);
      } else if (values.email && !values.phone_number) {
        const res = await Customer.isUniqueEmail(values.email);

        if (res?.status === 200) {
          props.handleChange(values);
          props.handleChangeActiveStep();
        } else {
          dispatch({
            type: ALERT_FAIL,
            payload: t('signup.form.emailError'),
          });
          setSubmitting(false);
        }
      } else if (!values.email && values.phone_number) {
        const mobileRes = await Customer.isMobile(values.phone_number);
        const mobileUniqueRes = await Customer.isMobileUnique(
          values.phone_number,
        );

        if (mobileRes?.status === 200 && mobileUniqueRes?.status === 200) {
          props.handleChange(values);
          props.handleChangeActiveStep();
        } else {
          dispatch({
            type: ALERT_FAIL,
            payload: t('signup.form.phoneError'),
          });
          if (values.phone_number !== '') {
            setSubmittedPhonenumberErr(values.phone_number);
          }
          setSubmitting(false);
        }
      } else if (values.email && values.phone_number) {
        const mobileRes = await Customer.isMobile(values.phone_number);
        const mobileUniqueRes = await Customer.isMobileUnique(
          values.phone_number,
        );
        const emailRes = await Customer.isUniqueEmail(values.email);

        const valid = {
          phone: false,
          email: false,
        };

        if (mobileRes?.status === 200 && mobileUniqueRes?.status === 200) {
          valid.phone = true;
        } else {
          if (values.phone_number !== '') {
            console.log('values.phone_number:', values.phone_number);
            setSubmittedPhonenumberErr(values.phone_number);
          }
        }

        if (emailRes?.status === 200) {
          valid.email = true;
        }

        if (valid.phone && valid.email) {
          props.handleChange(values);
          props.handleChangeActiveStep();
        } else if (!valid.phone) {
          dispatch({
            type: ALERT_FAIL,
            payload: t('signup.form.phoneError'),
          });
          setSubmitting(false);
        } else if (!valid.email) {
          dispatch({
            type: ALERT_FAIL,
            payload: t('signup.form.emailError'),
          });
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <React.Fragment>
      <Box
        mb={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Hidden smUp>
          <Box mt={1}>
            <Typography className={classes.step} color="primary" align="center">
              {t('signup.steps.stepOne')}
            </Typography>
          </Box>
        </Hidden>
        <Typography variant="h5" align="center">
          {t('signup.steps.setupCredentials')}
        </Typography>
        <Typography variant="body2">
          {t('signup.form.provideEmailOrPhone')}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PortalTextField
              className={classes.input}
              fullWidth
              id="email"
              name="email"
              placeholder={t('signup.form.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <PortalTextField
              id="phoneNum"
              name="phone_number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              inputComponent={NumberFormatCustom}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
              }
            />
            <FormHelperText>{t('signup.form.mobileNumber')}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <PortalTextField
              className={classes.input}
              fullWidth
              name="password"
              type={!show ? 'password' : 'text'}
              id="password"
              placeholder={t('signup.form.password')}
              value={formik.values.password}
              passwordRules="minlength: 8; required: lower; required: upper; required: digit; required: [-,?,@,#,$,%,!,$,^,*,/,_,-,\,[,],{,}]"
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              endAdornment={
                <InputAdornment className={classes.adornment} position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PortalTextField
              className={classes.input}
              fullWidth
              name="confirmPassword"
              type={!showConfirm ? 'password' : 'text'}
              id="confirmPassword"
              placeholder={t('signup.form.confirmPassword')}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              endAdornment={
                <InputAdornment className={classes.adornment} position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box ml={2} mb={2}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                s
              >
                {length ? (
                  <CheckCircleOutlineOutlinedIcon color="secondary" />
                ) : (
                  <HighlightOffOutlinedIcon />
                )}
                <Typography className={classes.text}>
                  {t('signup.form.passwordHasEightChars')}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {uppercase ? (
                  <CheckCircleOutlineOutlinedIcon color="secondary" />
                ) : (
                  <HighlightOffOutlinedIcon />
                )}
                <Typography className={classes.text}>
                  {t('signup.form.passwordHasOneUppercase')}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {lowercase ? (
                  <CheckCircleOutlineOutlinedIcon color="secondary" />
                ) : (
                  <HighlightOffOutlinedIcon />
                )}
                <Typography className={classes.text}>
                  {t('signup.form.passwordHasOneLowercase')}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {number ? (
                  <CheckCircleOutlineOutlinedIcon color="secondary" />
                ) : (
                  <HighlightOffOutlinedIcon />
                )}
                <Typography className={classes.text}>
                  {t('signup.form.passwordHasOneNumber')}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {specialCharacter ? (
                  <CheckCircleOutlineOutlinedIcon color="secondary" />
                ) : (
                  <HighlightOffOutlinedIcon />
                )}
                <Typography className={classes.text}>
                  {t('signup.form.passwordHasOneSpecialChar')}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {match ? (
                  <CheckCircleOutlineOutlinedIcon color="secondary" />
                ) : (
                  <HighlightOffOutlinedIcon />
                )}
                <Typography className={classes.text}>
                  {t('signup.form.passwordsMatch')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {enableBusinessService && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  checked={formik.values.type === 1}
                  value={1}
                  label={t('signup.wantToUseBusinessServices')}
                  name="type"
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue('type', 1);
                    } else {
                      formik.setFieldValue('type', 0);
                      formik.setFieldValue('business_name', '');
                    }
                  }}
                />
              </Grid>
              {formik.values.type === 1 && (
                <Grid item xs={12}>
                  <PortalTextField
                    className={classes.input}
                    fullWidth
                    name="business_name"
                    type="text"
                    id="business_name"
                    placeholder={t('account.form.businessName')}
                    value={formik.values.business_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.business_name &&
                      Boolean(formik.errors.business_name)
                    }
                    helperText={
                      formik.touched.business_name &&
                      formik.errors.business_name
                    }
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Box mt={3}>
          <Button
            variant="contained"
            onClick={props.handleBack}
            className={classes.button}
          >
            {t('buttons.back')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            className={classes.button}
            disabled={formik.isSubmitting}
          >
            {t('buttons.next')}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
}

AccountForm.propTypes = {
  handleChange: PropTypes.func,
};
